<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">
          <div class="form-body">
            <h3 class="box-title">Agenda</h3>
            <hr class="m-t-0 m-b-40" />

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.isi_ringkas"
                  name="isi_ringkas"
                  required
                  type="text"
                  label="Isi Ringkas"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.nomor_surat"
                  name="nomor_surat"
                  required
                  type="text"
                  label="Nomor Surat"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tanggal_surat"
                  name="tanggal_surat"
                  type="date"
                  required
                  label="Tanggal Surat"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tanggal_mulai"
                  name="tanggal_mulai"
                  type="date"
                  required
                  label="Tanggal Mulai"
                />
              </div>
            </div>           

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tanggal_selesai"
                  name="tanggal_selesai"
                  type="date"
                  label="Tanggal Selesai"
                />
              </div>
            </div>    
            
            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.waktu_mulai"
                  name="waktu_mulai"
                  type="time"
                  label="Waktu Mulai"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.waktu_selesai"
                  name="waktu_selesai"
                  type="time"
                  label="Waktu Selesai"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.tempat"
                  name="tempat"
                  type="text"
                  label="Tempat"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <Input
                  v-model="payload.asal_surat"
                  name="asal_surat"
                  required
                  type="text"
                  label="Asal Surat"
                />
              </div>
            </div>


            <hr class="m-t-10">
            <div class="row">
                    <div class="col-md-12">
                      <label :class="['control-label', 'col-md-12']">
                        Penindaklanjut
                      </label>
                      <div
                        :class="[{ invalid: isInvalidPenindaklanjut }, 'col-md-12']"
                      >
                        <Multiselect
                          v-model="payload.penindaklanjut"
                          label="text"
                          track-by="value"
                          placeholder="Pilih Penindaklanjut"
                          open-direction="bottom"
                          :options="dropdownPenindaklanjut.penindaklanjutList"
                          :multiple="true"
                          :searchable="true"
                          :value="dropdownPenindaklanjut.value"
                          :loading="dropdownPenindaklanjut.isLoadingPenindaklanjut"
                          :internal-search="false"
                          :clear-on-select="true"
                          :close-on-select="true"
                          :options-limit="300"
                          :max-height="600"
                          :show-no-results="true"
                          :hide-selected="true"
                          @input="onChangePenindaklanjut"
                          @search-change="penindaklanjut"
                        >
                          <span slot="noResult">Oops! No Data found.</span>
                        </Multiselect>
                        <label
                          class="typo__label form__label"
                          v-show="isInvalidPenindaklanjut"
                          >Must have at least one value</label
                        >
                      </div>
                    </div>
                  </div>
                  <hr />

                  

          </div>
          <hr />
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success">Simpan</button
                    >&nbsp;
                    <button
                      type="button"
                      @click="goBack()"
                      class="btn btn-inverse"
                    >
                      Batal
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.agenda;
    },
    optionJenisDokumen() {
      const jenisdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const jenisdokumenDropdown = [];
      if (jenisdokumen) {
        jenisdokumen.forEach((value) => {
          jenisdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return jenisdokumenDropdown;
    },
    optionSifatDokumen() {
      const sifatdokumen = JSON.parse(localStorage.getItem("refUnit"));
      const sifatdokumenDropdown = [];
      if (sifatdokumen) {
        sifatdokumen.forEach((value) => {
          sifatdokumenDropdown.push({ id: value.id, text: value.name });
        });
      }

      return sifatdokumenDropdown;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidJenisDokumen() {
      return (
        this.isTouchedJenisDokumen &&
        this.payload.valueJenisDokumen.length === 0
      );
    },
    isInvalidSifatDokumen() {
      return (
        this.isTouchedSifatDokumen &&
        this.payload.valueSifatDokumen.length === 0
      );
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPenindaklanjut() {
      return (
        this.dropdownPenindaklanjut.isTouched &&
        this.dropdownPenindaklanjut.value.length === 0
      );
    },
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      payload: {
        isi_ringkas: "",
        nomor_surat: "",
        tanggal_surat: "",
        tanggal_mulai: "",
        tanggal_selesai: "",
        waktu_mulai: "",
        waktu_selesai: "",
        tempat: "",
        asal_surat: "",
        penindaklanjut: [],
      },
      isDisabled: false,
      dropdownPenindaklanjut: {
        isTouched: false,
        penindaklanjutList: [],
        value: [],
        isLoadingPenindaklanjut: false,
      },
    };
  },
  async mounted() {
    var query = "";
    this.penindaklanjut(query);
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/agenda/${this.$route.params.id}`).then((response) => {
            const state = {
              loaded: true,
            };
            this.$store.commit("agenda/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("agenda/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        isi_ringkas: data.isi_ringkas,
        nomor_surat: data.nomor_surat,
        tanggal_surat: data.tanggal_surat,
        tanggal_mulai: data.tanggal_mulai,
        waktu_mulai: data.waktu_mulai,
        waktu_selesai: data.waktu_selesai,
        tempat: data.tempat,
        tanggal_surat: data.tanggal_surat,
        asal_surat: data.asal_surat,
        penindaklanjut: this.payload.penindaklanjut,
      };
    },
    penindaklanjut(query) {
      this.dropdownPenindaklanjut.isLoadingPenindaklanjut = true;
      axios
        .get(`document_in/list_agenda?s=${query}`)
        .then((res) => {
          this.dropdownPenindaklanjut.penindaklanjutList = res.data.items;
          this.dropdownPenindaklanjut.isLoadingPenindaklanjut = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onTouchJenisDokumen() {
      this.isTouchedJenisDokumen = true;
    },
    onTouchSifatDokumen() {
      this.isTouchedSifatDokumen = true;
    },
    onTouchPenindaklanjut() {
      this.dropdownPenindaklanjut.isTouched = true;
    },
    onChangePenindaklanjut(value) {
      this.dropdownPenindaklanjut.value = value;
    },

    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeJenisDokumen(value) {
      this.payload.valueJenisDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueJenisDokumen = [];
    },
    onChangeSifatDokumen(value) {
      this.payload.valueSifatDokumen = value;
      if (value.indexOf("Reset me!") !== -1)
        this.payload.valueSifatDokumen = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("agenda/onCancel");
    },
    submit() {

      const payload = {
        isi_ringkas: this.payload.isi_ringkas,
        nomor_surat: this.payload.nomor_surat,
        tanggal_surat: this.payload.tanggal_surat,
        tanggal_mulai: this.payload.tanggal_mulai,
        tanggal_selesai: this.payload.tanggal_selesai,
        waktu_mulai: this.payload.waktu_mulai,
        waktu_selesai: this.payload.waktu_selesai,
        tempat: this.payload.tempat,
        asal_surat: this.payload.asal_surat,
        penindaklanjut: this.payload.penindaklanjut,
      };
      const data = JSON.stringify(payload);
      // this.$validator.validateAll().then((success) => {
      //   if (success) {
          if (this.editMode) {
            this.$store.dispatch("agenda/submitEdit", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("agenda/submitAdd", data);
          }
      //   }
      // });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

